export const HTTPMethodEnum = {
  DELETE: 'DELETE',
  GET: 'GET',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
  PATCH: 'PATCH',
  POST: 'POST',
  PUT: 'PUT',
  TRACE: 'TRACE',
  UNKNOWN: 'X_HORIZON_UNDEFINED_HTTP_METHOD',
  X_HORIZON_UNDEFINED_HTTP_METHOD: 'X_HORIZON_UNDEFINED_HTTP_METHOD',
};

export const ConnectivityScanStatus = {
  NOT_REACHABLE: 'Not reachable',
  NOT_REACHABLE_CONNECTION_REJECTED: 'Not reachable',
  NOT_REACHABLE_CONNECTION_TIMED_OUT: 'Not reachable',
  NOT_REACHABLE_DNS_LOOKUP_FAILED: 'Not reachable',
  REACHABLE: 'Online',
};

export const DiscoveredVia = {
  AKAMAI: 'AKAMAI',
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES',
  APIGEE: 'APIGEE',
  APIGEE_ON_PREM: 'APIGEE_ON_PREM',
  APPLICATION_FINGERPRINTING: 'APPLICATION_FINGERPRINTING',
  AXWAY: 'AXWAY',
  AZURE: 'AZURE',
  AZURE_DEVOPS_INTEGRATION: 'AZURE_DEVOPS_INTEGRATION',
  BITBUCKET_INTEGRATION: 'BITBUCKET_INTEGRATION',
  CERTIFICATE_TRANSPARENCY: 'CERTIFICATE_TRANSPARENCY',
  CLOUDFLARE: 'CLOUDFLARE',
  CLOUD_PROTECT: 'CLOUD_PROTECT',
  GITHUB_ENTERPRISE_SERVER_INTEGRATION: 'GITHUB_ENTERPRISE_SERVER_INTEGRATION',
  GITHUB_INTEGRATION: 'GITHUB_INTEGRATION',
  GITLAB_INTEGRATION: 'GITLAB_INTEGRATION',
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM',
  KONG: 'KONG',
  KUBERNETES_CLUSTER_ANALYSIS: 'KUBERNETES_CLUSTER_ANALYSIS',
  MANUAL_IMPORT: 'MANUAL_IMPORT',
  MOBILE_APP_SCANS: 'MOBILE_APP_SCANS',
  MOBILE_APP_STORE: 'MOBILE_APP_STORE',
  MOBILE_PROTECT_USAGE: 'MOBILE_PROTECT_USAGE',
  MULESOFT: 'MULESOFT',
  POSTMAN_CLOUD: 'POSTMAN_CLOUD',
  RESTFUL_API_DISCOVERER: 'RESTFUL_API_DISCOVERER',
  REVERSE_IP_LOOKUP: 'REVERSE_IP_LOOKUP',
  SAN_EXTRACTION: 'SAN_EXTRACTION',
  SHADOW_RESTFUL_API_DISCOVERER: 'SHADOW_RESTFUL_API_DISCOVERER',
  SWAGGER_HUB: 'SWAGGER_HUB',
  WEBSITE_CRAWLER: 'WEBSITE_CRAWLER',
  WEB_APPLICATION_SCANS: 'WEB_APPLICATION_SCANS',
};

/** Network Service */
export const HostedOnEnum = {
  AKAMAI: 'AKAMAI',
  AMAZON_WEB_SERVICES: 'AMAZON_WEB_SERVICES',
  APIGEE: 'APIGEE',
  AXWAY: 'AXWAY',
  AZURE: 'AZURE',
  BITBUCKET: 'BITBUCKET',
  CLOUDFLARE: 'CLOUDFLARE',
  DETECTION_FAILED: 'DETECTION_FAILED',
  GITHUB: 'GITHUB',
  GITHUB_ENTERPRISE_SERVER: 'GITHUB_ENTERPRISE_SERVER',
  GOOGLE_CLOUD_PLATFORM: 'GOOGLE_CLOUD_PLATFORM',
  KONG: 'KONG',
  MULESOFT: 'MULESOFT',
  ON_PREMISE: 'ON_PREMISE',
  UNKNOWN: 'UNKNOWN',
};

/** Policy Violation */
export const PolicyRuleTypeRelevance = {
  IMPORTANT: 'IMPORTANT',
  INFORMATIONAL: 'INFORMATIONAL',
  PROACTIVE: 'PROACTIVE',
  URGENT: 'URGENT',
};

export const PolicyComplianceStandards = {
  CIS_BENCHMARK: 'CIS_BENCHMARK',
  FEDRAMP_V5_API_SECURITY: 'FEDRAMP_V5_API_SECURITY',
  FFIEC_VC2: 'FFIEC_VC2',
  NIST_800_53: 'NIST_800_53',
  OWASP: 'OWASP',
  PCI_DSS: 'PCI_DSS',
};

export const PolicyComplianceStandardCriteria = {
  CIS_BENCHMARK_AWS_1: 'CIS_BENCHMARK_AWS_1',
  CIS_BENCHMARK_AWS_2: 'CIS_BENCHMARK_AWS_2',
  CIS_BENCHMARK_AWS_3: 'CIS_BENCHMARK_AWS_3',
  CIS_BENCHMARK_AZURE_3: 'CIS_BENCHMARK_AZURE_3',
  CIS_BENCHMARK_AZURE_4: 'CIS_BENCHMARK_AZURE_4',
  CIS_BENCHMARK_AZURE_6: 'CIS_BENCHMARK_AZURE_6',
  CIS_BENCHMARK_AZURE_7: 'CIS_BENCHMARK_AZURE_7',
  CIS_BENCHMARK_AZURE_8: 'CIS_BENCHMARK_AZURE_8',
  CIS_BENCHMARK_AZURE_9: 'CIS_BENCHMARK_AZURE_9',
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_1',
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_3',
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_4',
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_5',
  CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6: 'CIS_BENCHMARK_GOOGLE_CLOUD_PLATFORM_6',
  CIS_BENCHMARK_KUBERNETES_1: 'CIS_BENCHMARK_KUBERNETES_1',
  CIS_BENCHMARK_KUBERNETES_3: 'CIS_BENCHMARK_KUBERNETES_3',
  CIS_BENCHMARK_KUBERNETES_5: 'CIS_BENCHMARK_KUBERNETES_5',
  FEDRAMP_V5_API_SECURITY_DAST: 'FEDRAMP_V5_API_SECURITY_DAST',
  FEDRAMP_V5_API_SECURITY_SAST: 'FEDRAMP_V5_API_SECURITY_SAST',
  FFIEC_VC2_REQUIREMENT_1: 'FFIEC_VC2_REQUIREMENT_1',
  FFIEC_VC2_REQUIREMENT_2: 'FFIEC_VC2_REQUIREMENT_2',
  FFIEC_VC2_REQUIREMENT_3: 'FFIEC_VC2_REQUIREMENT_3',
  FFIEC_VC2_REQUIREMENT_4: 'FFIEC_VC2_REQUIREMENT_4',
  FFIEC_VC2_REQUIREMENT_5: 'FFIEC_VC2_REQUIREMENT_5',
  FFIEC_VC2_REQUIREMENT_6: 'FFIEC_VC2_REQUIREMENT_6',
  FFIEC_VC2_REQUIREMENT_7: 'FFIEC_VC2_REQUIREMENT_7',
  FFIEC_VC2_REQUIREMENT_8: 'FFIEC_VC2_REQUIREMENT_8',
  FFIEC_VC2_REQUIREMENT_9: 'FFIEC_VC2_REQUIREMENT_9',
  NIST_800_53_AC_1: 'NIST_800_53_AC_1',
  NIST_800_53_AC_4: 'NIST_800_53_AC_4',
  NIST_800_53_AU_11: 'NIST_800_53_AU_11',
  NIST_800_53_CM_2: 'NIST_800_53_CM_2',
  NIST_800_53_CM_4: 'NIST_800_53_CM_4',
  NIST_800_53_SA_2: 'NIST_800_53_SA_2',
  NIST_800_53_SA_4: 'NIST_800_53_SA_4',
  NIST_800_53_SC_1: 'NIST_800_53_SC_1',
  NIST_800_53_SC_12: 'NIST_800_53_SC_12',
  NIST_800_53_SC_16: 'NIST_800_53_SC_16',
  NIST_800_53_SC_7: 'NIST_800_53_SC_7',
  NIST_800_53_SI_1: 'NIST_800_53_SI_1',
  NIST_800_53_SI_2: 'NIST_800_53_SI_2',
  OWASP_A10_2019: 'OWASP_A10_2019',
  OWASP_A10_2023: 'OWASP_A10_2023',
  OWASP_A1_2019: 'OWASP_A1_2019',
  OWASP_A1_2023: 'OWASP_A1_2023',
  OWASP_A2_2019: 'OWASP_A2_2019',
  OWASP_A2_2023: 'OWASP_A2_2023',
  OWASP_A3_2019: 'OWASP_A3_2019',
  OWASP_A3_2023: 'OWASP_A3_2023',
  OWASP_A4_2019: 'OWASP_A4_2019',
  OWASP_A4_2023: 'OWASP_A4_2023',
  OWASP_A5_2019: 'OWASP_A5_2019',
  OWASP_A5_2023: 'OWASP_A5_2023',
  OWASP_A6_2019: 'OWASP_A6_2019',
  OWASP_A6_2023: 'OWASP_A6_2023',
  OWASP_A7_2019: 'OWASP_A7_2019',
  OWASP_A7_2023: 'OWASP_A7_2023',
  OWASP_A8_2019: 'OWASP_A8_2019',
  OWASP_A8_2023: 'OWASP_A8_2023',
  OWASP_A9_2019: 'OWASP_A9_2019',
  OWASP_A9_2023: 'OWASP_A9_2023',
  OWASP_ASVS_V_10_1: 'OWASP_ASVS_V_10_1',
  OWASP_ASVS_V_10_10: 'OWASP_ASVS_V_10_10',
  OWASP_ASVS_V_10_2: 'OWASP_ASVS_V_10_2',
  OWASP_ASVS_V_10_3: 'OWASP_ASVS_V_10_3',
  OWASP_ASVS_V_10_4: 'OWASP_ASVS_V_10_4',
  OWASP_ASVS_V_10_5: 'OWASP_ASVS_V_10_5',
  OWASP_ASVS_V_10_6: 'OWASP_ASVS_V_10_6',
  OWASP_ASVS_V_10_7: 'OWASP_ASVS_V_10_7',
  OWASP_ASVS_V_10_8: 'OWASP_ASVS_V_10_8',
  OWASP_ASVS_V_10_9: 'OWASP_ASVS_V_10_9',
  OWASP_ASVS_V_11_1: 'OWASP_ASVS_V_11_1',
  OWASP_ASVS_V_11_10: 'OWASP_ASVS_V_11_10',
  OWASP_ASVS_V_11_2: 'OWASP_ASVS_V_11_2',
  OWASP_ASVS_V_11_3: 'OWASP_ASVS_V_11_3',
  OWASP_ASVS_V_11_4: 'OWASP_ASVS_V_11_4',
  OWASP_ASVS_V_11_5: 'OWASP_ASVS_V_11_5',
  OWASP_ASVS_V_11_6: 'OWASP_ASVS_V_11_6',
  OWASP_ASVS_V_11_7: 'OWASP_ASVS_V_11_7',
  OWASP_ASVS_V_11_8: 'OWASP_ASVS_V_11_8',
  OWASP_ASVS_V_11_9: 'OWASP_ASVS_V_11_9',
  OWASP_ASVS_V_12_1: 'OWASP_ASVS_V_12_1',
  OWASP_ASVS_V_12_10: 'OWASP_ASVS_V_12_10',
  OWASP_ASVS_V_12_2: 'OWASP_ASVS_V_12_2',
  OWASP_ASVS_V_12_3: 'OWASP_ASVS_V_12_3',
  OWASP_ASVS_V_12_4: 'OWASP_ASVS_V_12_4',
  OWASP_ASVS_V_12_5: 'OWASP_ASVS_V_12_5',
  OWASP_ASVS_V_12_6: 'OWASP_ASVS_V_12_6',
  OWASP_ASVS_V_12_7: 'OWASP_ASVS_V_12_7',
  OWASP_ASVS_V_12_8: 'OWASP_ASVS_V_12_8',
  OWASP_ASVS_V_12_9: 'OWASP_ASVS_V_12_9',
  OWASP_ASVS_V_13_1: 'OWASP_ASVS_V_13_1',
  OWASP_ASVS_V_13_10: 'OWASP_ASVS_V_13_10',
  OWASP_ASVS_V_13_2: 'OWASP_ASVS_V_13_2',
  OWASP_ASVS_V_13_3: 'OWASP_ASVS_V_13_3',
  OWASP_ASVS_V_13_4: 'OWASP_ASVS_V_13_4',
  OWASP_ASVS_V_13_5: 'OWASP_ASVS_V_13_5',
  OWASP_ASVS_V_13_6: 'OWASP_ASVS_V_13_6',
  OWASP_ASVS_V_13_7: 'OWASP_ASVS_V_13_7',
  OWASP_ASVS_V_13_8: 'OWASP_ASVS_V_13_8',
  OWASP_ASVS_V_13_9: 'OWASP_ASVS_V_13_9',
  OWASP_ASVS_V_14_1: 'OWASP_ASVS_V_14_1',
  OWASP_ASVS_V_14_10: 'OWASP_ASVS_V_14_10',
  OWASP_ASVS_V_14_2: 'OWASP_ASVS_V_14_2',
  OWASP_ASVS_V_14_3: 'OWASP_ASVS_V_14_3',
  OWASP_ASVS_V_14_4: 'OWASP_ASVS_V_14_4',
  OWASP_ASVS_V_14_5: 'OWASP_ASVS_V_14_5',
  OWASP_ASVS_V_14_6: 'OWASP_ASVS_V_14_6',
  OWASP_ASVS_V_14_7: 'OWASP_ASVS_V_14_7',
  OWASP_ASVS_V_14_8: 'OWASP_ASVS_V_14_8',
  OWASP_ASVS_V_14_9: 'OWASP_ASVS_V_14_9',
  OWASP_ASVS_V_1_1: 'OWASP_ASVS_V_1_1',
  OWASP_ASVS_V_1_10: 'OWASP_ASVS_V_1_10',
  OWASP_ASVS_V_1_11: 'OWASP_ASVS_V_1_11',
  OWASP_ASVS_V_1_12: 'OWASP_ASVS_V_1_12',
  OWASP_ASVS_V_1_13: 'OWASP_ASVS_V_1_13',
  OWASP_ASVS_V_1_14: 'OWASP_ASVS_V_1_14',
  OWASP_ASVS_V_1_2: 'OWASP_ASVS_V_1_2',
  OWASP_ASVS_V_1_3: 'OWASP_ASVS_V_1_3',
  OWASP_ASVS_V_1_4: 'OWASP_ASVS_V_1_4',
  OWASP_ASVS_V_1_5: 'OWASP_ASVS_V_1_5',
  OWASP_ASVS_V_1_6: 'OWASP_ASVS_V_1_6',
  OWASP_ASVS_V_1_7: 'OWASP_ASVS_V_1_7',
  OWASP_ASVS_V_1_8: 'OWASP_ASVS_V_1_8',
  OWASP_ASVS_V_1_9: 'OWASP_ASVS_V_1_9',
  OWASP_ASVS_V_2_1: 'OWASP_ASVS_V_2_1',
  OWASP_ASVS_V_2_10: 'OWASP_ASVS_V_2_10',
  OWASP_ASVS_V_2_2: 'OWASP_ASVS_V_2_2',
  OWASP_ASVS_V_2_3: 'OWASP_ASVS_V_2_3',
  OWASP_ASVS_V_2_4: 'OWASP_ASVS_V_2_4',
  OWASP_ASVS_V_2_5: 'OWASP_ASVS_V_2_5',
  OWASP_ASVS_V_2_6: 'OWASP_ASVS_V_2_6',
  OWASP_ASVS_V_2_7: 'OWASP_ASVS_V_2_7',
  OWASP_ASVS_V_2_8: 'OWASP_ASVS_V_2_8',
  OWASP_ASVS_V_2_9: 'OWASP_ASVS_V_2_9',
  OWASP_ASVS_V_3_1: 'OWASP_ASVS_V_3_1',
  OWASP_ASVS_V_3_10: 'OWASP_ASVS_V_3_10',
  OWASP_ASVS_V_3_2: 'OWASP_ASVS_V_3_2',
  OWASP_ASVS_V_3_3: 'OWASP_ASVS_V_3_3',
  OWASP_ASVS_V_3_4: 'OWASP_ASVS_V_3_4',
  OWASP_ASVS_V_3_5: 'OWASP_ASVS_V_3_5',
  OWASP_ASVS_V_3_6: 'OWASP_ASVS_V_3_6',
  OWASP_ASVS_V_3_7: 'OWASP_ASVS_V_3_7',
  OWASP_ASVS_V_3_8: 'OWASP_ASVS_V_3_8',
  OWASP_ASVS_V_3_9: 'OWASP_ASVS_V_3_9',
  OWASP_ASVS_V_4_1: 'OWASP_ASVS_V_4_1',
  OWASP_ASVS_V_4_10: 'OWASP_ASVS_V_4_10',
  OWASP_ASVS_V_4_2: 'OWASP_ASVS_V_4_2',
  OWASP_ASVS_V_4_3: 'OWASP_ASVS_V_4_3',
  OWASP_ASVS_V_4_4: 'OWASP_ASVS_V_4_4',
  OWASP_ASVS_V_4_5: 'OWASP_ASVS_V_4_5',
  OWASP_ASVS_V_4_6: 'OWASP_ASVS_V_4_6',
  OWASP_ASVS_V_4_7: 'OWASP_ASVS_V_4_7',
  OWASP_ASVS_V_4_8: 'OWASP_ASVS_V_4_8',
  OWASP_ASVS_V_4_9: 'OWASP_ASVS_V_4_9',
  OWASP_ASVS_V_5_1: 'OWASP_ASVS_V_5_1',
  OWASP_ASVS_V_5_10: 'OWASP_ASVS_V_5_10',
  OWASP_ASVS_V_5_2: 'OWASP_ASVS_V_5_2',
  OWASP_ASVS_V_5_3: 'OWASP_ASVS_V_5_3',
  OWASP_ASVS_V_5_4: 'OWASP_ASVS_V_5_4',
  OWASP_ASVS_V_5_5: 'OWASP_ASVS_V_5_5',
  OWASP_ASVS_V_5_6: 'OWASP_ASVS_V_5_6',
  OWASP_ASVS_V_5_7: 'OWASP_ASVS_V_5_7',
  OWASP_ASVS_V_5_8: 'OWASP_ASVS_V_5_8',
  OWASP_ASVS_V_5_9: 'OWASP_ASVS_V_5_9',
  OWASP_ASVS_V_6_1: 'OWASP_ASVS_V_6_1',
  OWASP_ASVS_V_6_10: 'OWASP_ASVS_V_6_10',
  OWASP_ASVS_V_6_2: 'OWASP_ASVS_V_6_2',
  OWASP_ASVS_V_6_3: 'OWASP_ASVS_V_6_3',
  OWASP_ASVS_V_6_4: 'OWASP_ASVS_V_6_4',
  OWASP_ASVS_V_6_5: 'OWASP_ASVS_V_6_5',
  OWASP_ASVS_V_6_6: 'OWASP_ASVS_V_6_6',
  OWASP_ASVS_V_6_7: 'OWASP_ASVS_V_6_7',
  OWASP_ASVS_V_6_8: 'OWASP_ASVS_V_6_8',
  OWASP_ASVS_V_6_9: 'OWASP_ASVS_V_6_9',
  OWASP_ASVS_V_7_1: 'OWASP_ASVS_V_7_1',
  OWASP_ASVS_V_7_10: 'OWASP_ASVS_V_7_10',
  OWASP_ASVS_V_7_2: 'OWASP_ASVS_V_7_2',
  OWASP_ASVS_V_7_3: 'OWASP_ASVS_V_7_3',
  OWASP_ASVS_V_7_4: 'OWASP_ASVS_V_7_4',
  OWASP_ASVS_V_7_5: 'OWASP_ASVS_V_7_5',
  OWASP_ASVS_V_7_6: 'OWASP_ASVS_V_7_6',
  OWASP_ASVS_V_7_7: 'OWASP_ASVS_V_7_7',
  OWASP_ASVS_V_7_8: 'OWASP_ASVS_V_7_8',
  OWASP_ASVS_V_7_9: 'OWASP_ASVS_V_7_9',
  OWASP_ASVS_V_8_1: 'OWASP_ASVS_V_8_1',
  OWASP_ASVS_V_8_10: 'OWASP_ASVS_V_8_10',
  OWASP_ASVS_V_8_2: 'OWASP_ASVS_V_8_2',
  OWASP_ASVS_V_8_3: 'OWASP_ASVS_V_8_3',
  OWASP_ASVS_V_8_4: 'OWASP_ASVS_V_8_4',
  OWASP_ASVS_V_8_5: 'OWASP_ASVS_V_8_5',
  OWASP_ASVS_V_8_6: 'OWASP_ASVS_V_8_6',
  OWASP_ASVS_V_8_7: 'OWASP_ASVS_V_8_7',
  OWASP_ASVS_V_8_8: 'OWASP_ASVS_V_8_8',
  OWASP_ASVS_V_8_9: 'OWASP_ASVS_V_8_9',
  OWASP_ASVS_V_9_1: 'OWASP_ASVS_V_9_1',
  OWASP_ASVS_V_9_10: 'OWASP_ASVS_V_9_10',
  OWASP_ASVS_V_9_2: 'OWASP_ASVS_V_9_2',
  OWASP_ASVS_V_9_3: 'OWASP_ASVS_V_9_3',
  OWASP_ASVS_V_9_4: 'OWASP_ASVS_V_9_4',
  OWASP_ASVS_V_9_5: 'OWASP_ASVS_V_9_5',
  OWASP_ASVS_V_9_6: 'OWASP_ASVS_V_9_6',
  OWASP_ASVS_V_9_7: 'OWASP_ASVS_V_9_7',
  OWASP_ASVS_V_9_8: 'OWASP_ASVS_V_9_8',
  OWASP_ASVS_V_9_9: 'OWASP_ASVS_V_9_9',
  OWASP_M10_2014: 'OWASP_M10_2014',
  OWASP_M10_2016: 'OWASP_M10_2016',
  OWASP_M1_2014: 'OWASP_M1_2014',
  OWASP_M1_2016: 'OWASP_M1_2016',
  OWASP_M2_2014: 'OWASP_M2_2014',
  OWASP_M2_2016: 'OWASP_M2_2016',
  OWASP_M3_2014: 'OWASP_M3_2014',
  OWASP_M3_2016: 'OWASP_M3_2016',
  OWASP_M4_2014: 'OWASP_M4_2014',
  OWASP_M4_2016: 'OWASP_M4_2016',
  OWASP_M5_2014: 'OWASP_M5_2014',
  OWASP_M5_2016: 'OWASP_M5_2016',
  OWASP_M6_2014: 'OWASP_M6_2014',
  OWASP_M6_2016: 'OWASP_M6_2016',
  OWASP_M7_2014: 'OWASP_M7_2014',
  OWASP_M7_2016: 'OWASP_M7_2016',
  OWASP_M8_2014: 'OWASP_M8_2014',
  OWASP_M8_2016: 'OWASP_M8_2016',
  OWASP_M9_2014: 'OWASP_M9_2014',
  OWASP_M9_2016: 'OWASP_M9_2016',
  OWASP_MSTG_ARCH_1: 'OWASP_MSTG_ARCH_1',
  OWASP_MSTG_ARCH_10: 'OWASP_MSTG_ARCH_10',
  OWASP_MSTG_ARCH_11: 'OWASP_MSTG_ARCH_11',
  OWASP_MSTG_ARCH_12: 'OWASP_MSTG_ARCH_12',
  OWASP_MSTG_ARCH_2: 'OWASP_MSTG_ARCH_2',
  OWASP_MSTG_ARCH_3: 'OWASP_MSTG_ARCH_3',
  OWASP_MSTG_ARCH_4: 'OWASP_MSTG_ARCH_4',
  OWASP_MSTG_ARCH_5: 'OWASP_MSTG_ARCH_5',
  OWASP_MSTG_ARCH_6: 'OWASP_MSTG_ARCH_6',
  OWASP_MSTG_ARCH_7: 'OWASP_MSTG_ARCH_7',
  OWASP_MSTG_ARCH_8: 'OWASP_MSTG_ARCH_8',
  OWASP_MSTG_ARCH_9: 'OWASP_MSTG_ARCH_9',
  OWASP_MSTG_AUTH_1: 'OWASP_MSTG_AUTH_1',
  OWASP_MSTG_AUTH_10: 'OWASP_MSTG_AUTH_10',
  OWASP_MSTG_AUTH_11: 'OWASP_MSTG_AUTH_11',
  OWASP_MSTG_AUTH_12: 'OWASP_MSTG_AUTH_12',
  OWASP_MSTG_AUTH_2: 'OWASP_MSTG_AUTH_2',
  OWASP_MSTG_AUTH_3: 'OWASP_MSTG_AUTH_3',
  OWASP_MSTG_AUTH_4: 'OWASP_MSTG_AUTH_4',
  OWASP_MSTG_AUTH_5: 'OWASP_MSTG_AUTH_5',
  OWASP_MSTG_AUTH_6: 'OWASP_MSTG_AUTH_6',
  OWASP_MSTG_AUTH_7: 'OWASP_MSTG_AUTH_7',
  OWASP_MSTG_AUTH_8: 'OWASP_MSTG_AUTH_8',
  OWASP_MSTG_AUTH_9: 'OWASP_MSTG_AUTH_9',
  OWASP_MSTG_CODE_1: 'OWASP_MSTG_CODE_1',
  OWASP_MSTG_CODE_2: 'OWASP_MSTG_CODE_2',
  OWASP_MSTG_CODE_3: 'OWASP_MSTG_CODE_3',
  OWASP_MSTG_CODE_4: 'OWASP_MSTG_CODE_4',
  OWASP_MSTG_CODE_5: 'OWASP_MSTG_CODE_5',
  OWASP_MSTG_CODE_6: 'OWASP_MSTG_CODE_6',
  OWASP_MSTG_CODE_7: 'OWASP_MSTG_CODE_7',
  OWASP_MSTG_CODE_8: 'OWASP_MSTG_CODE_8',
  OWASP_MSTG_CODE_9: 'OWASP_MSTG_CODE_9',
  OWASP_MSTG_CRYPTO_1: 'OWASP_MSTG_CRYPTO_1',
  OWASP_MSTG_CRYPTO_2: 'OWASP_MSTG_CRYPTO_2',
  OWASP_MSTG_CRYPTO_3: 'OWASP_MSTG_CRYPTO_3',
  OWASP_MSTG_CRYPTO_4: 'OWASP_MSTG_CRYPTO_4',
  OWASP_MSTG_CRYPTO_5: 'OWASP_MSTG_CRYPTO_5',
  OWASP_MSTG_CRYPTO_6: 'OWASP_MSTG_CRYPTO_6',
  OWASP_MSTG_NETWORK_1: 'OWASP_MSTG_NETWORK_1',
  OWASP_MSTG_NETWORK_2: 'OWASP_MSTG_NETWORK_2',
  OWASP_MSTG_NETWORK_3: 'OWASP_MSTG_NETWORK_3',
  OWASP_MSTG_NETWORK_4: 'OWASP_MSTG_NETWORK_4',
  OWASP_MSTG_NETWORK_5: 'OWASP_MSTG_NETWORK_5',
  OWASP_MSTG_NETWORK_6: 'OWASP_MSTG_NETWORK_6',
  OWASP_MSTG_PLATFORM_1: 'OWASP_MSTG_PLATFORM_1',
  OWASP_MSTG_PLATFORM_10: 'OWASP_MSTG_PLATFORM_10',
  OWASP_MSTG_PLATFORM_11: 'OWASP_MSTG_PLATFORM_11',
  OWASP_MSTG_PLATFORM_2: 'OWASP_MSTG_PLATFORM_2',
  OWASP_MSTG_PLATFORM_3: 'OWASP_MSTG_PLATFORM_3',
  OWASP_MSTG_PLATFORM_4: 'OWASP_MSTG_PLATFORM_4',
  OWASP_MSTG_PLATFORM_5: 'OWASP_MSTG_PLATFORM_5',
  OWASP_MSTG_PLATFORM_6: 'OWASP_MSTG_PLATFORM_6',
  OWASP_MSTG_PLATFORM_7: 'OWASP_MSTG_PLATFORM_7',
  OWASP_MSTG_PLATFORM_8: 'OWASP_MSTG_PLATFORM_8',
  OWASP_MSTG_PLATFORM_9: 'OWASP_MSTG_PLATFORM_9',
  OWASP_MSTG_RESILIENCE_1: 'OWASP_MSTG_RESILIENCE_1',
  OWASP_MSTG_RESILIENCE_10: 'OWASP_MSTG_RESILIENCE_10',
  OWASP_MSTG_RESILIENCE_11: 'OWASP_MSTG_RESILIENCE_11',
  OWASP_MSTG_RESILIENCE_12: 'OWASP_MSTG_RESILIENCE_12',
  OWASP_MSTG_RESILIENCE_13: 'OWASP_MSTG_RESILIENCE_13',
  OWASP_MSTG_RESILIENCE_2: 'OWASP_MSTG_RESILIENCE_2',
  OWASP_MSTG_RESILIENCE_3: 'OWASP_MSTG_RESILIENCE_3',
  OWASP_MSTG_RESILIENCE_4: 'OWASP_MSTG_RESILIENCE_4',
  OWASP_MSTG_RESILIENCE_5: 'OWASP_MSTG_RESILIENCE_5',
  OWASP_MSTG_RESILIENCE_6: 'OWASP_MSTG_RESILIENCE_6',
  OWASP_MSTG_RESILIENCE_7: 'OWASP_MSTG_RESILIENCE_7',
  OWASP_MSTG_RESILIENCE_8: 'OWASP_MSTG_RESILIENCE_8',
  OWASP_MSTG_RESILIENCE_9: 'OWASP_MSTG_RESILIENCE_9',
  OWASP_MSTG_STORAGE_1: 'OWASP_MSTG_STORAGE_1',
  OWASP_MSTG_STORAGE_10: 'OWASP_MSTG_STORAGE_10',
  OWASP_MSTG_STORAGE_11: 'OWASP_MSTG_STORAGE_11',
  OWASP_MSTG_STORAGE_12: 'OWASP_MSTG_STORAGE_12',
  OWASP_MSTG_STORAGE_13: 'OWASP_MSTG_STORAGE_13',
  OWASP_MSTG_STORAGE_14: 'OWASP_MSTG_STORAGE_14',
  OWASP_MSTG_STORAGE_15: 'OWASP_MSTG_STORAGE_15',
  OWASP_MSTG_STORAGE_2: 'OWASP_MSTG_STORAGE_2',
  OWASP_MSTG_STORAGE_3: 'OWASP_MSTG_STORAGE_3',
  OWASP_MSTG_STORAGE_4: 'OWASP_MSTG_STORAGE_4',
  OWASP_MSTG_STORAGE_5: 'OWASP_MSTG_STORAGE_5',
  OWASP_MSTG_STORAGE_6: 'OWASP_MSTG_STORAGE_6',
  OWASP_MSTG_STORAGE_7: 'OWASP_MSTG_STORAGE_7',
  OWASP_MSTG_STORAGE_8: 'OWASP_MSTG_STORAGE_8',
  OWASP_MSTG_STORAGE_9: 'OWASP_MSTG_STORAGE_9',
  OWASP_R10_2011: 'OWASP_R10_2011',
  OWASP_R1_2011: 'OWASP_R1_2011',
  OWASP_R2_2011: 'OWASP_R2_2011',
  OWASP_R3_2011: 'OWASP_R3_2011',
  OWASP_R4_2011: 'OWASP_R4_2011',
  OWASP_R5_2011: 'OWASP_R5_2011',
  OWASP_R6_2011: 'OWASP_R6_2011',
  OWASP_R7_2011: 'OWASP_R7_2011',
  OWASP_R8_2011: 'OWASP_R8_2011',
  OWASP_R9_2011: 'OWASP_R9_2011',
  OWASP_W10_2021: 'OWASP_W10_2021',
  OWASP_W1_2021: 'OWASP_W1_2021',
  OWASP_W2_2021: 'OWASP_W2_2021',
  OWASP_W3_2021: 'OWASP_W3_2021',
  OWASP_W4_2021: 'OWASP_W4_2021',
  OWASP_W5_2021: 'OWASP_W5_2021',
  OWASP_W6_2021: 'OWASP_W6_2021',
  OWASP_W7_2021: 'OWASP_W7_2021',
  OWASP_W8_2021: 'OWASP_W8_2021',
  OWASP_W9_2021: 'OWASP_W9_2021',
  PCI_DSS_v3_2_1_a10_1: 'PCI_DSS_v3_2_1_a10_1',
  PCI_DSS_v3_2_1_a1_2_1: 'PCI_DSS_v3_2_1_a1_2_1',
  PCI_DSS_v3_2_1_a1_3: 'PCI_DSS_v3_2_1_a1_3',
  PCI_DSS_v3_2_1_a2_2_3: 'PCI_DSS_v3_2_1_a2_2_3',
  PCI_DSS_v3_2_1_a2_3: 'PCI_DSS_v3_2_1_a2_3',
  PCI_DSS_v3_2_1_a3_4: 'PCI_DSS_v3_2_1_a3_4',
  PCI_DSS_v3_2_1_a4_1: 'PCI_DSS_v3_2_1_a4_1',
  PCI_DSS_v3_2_1_a6_1: 'PCI_DSS_v3_2_1_a6_1',
  PCI_DSS_v3_2_1_a6_5_10: 'PCI_DSS_v3_2_1_a6_5_10',
  PCI_DSS_v3_2_1_a6_6: 'PCI_DSS_v3_2_1_a6_6',
  PCI_DSS_v3_2_1_a8_7: 'PCI_DSS_v3_2_1_a8_7',
  PCI_DSS_v3_2_1_aA_2_1: 'PCI_DSS_v3_2_1_aA_2_1',
};

export const PolicyRuleGroupEnum = {
  CLOUD_SECURITY: 'CLOUD_SECURITY',
  ENCRYPTION: 'ENCRYPTION',
  REST_API_AND_SERVERLESS: 'REST_API_AND_SERVERLESS',
  WEB_APPS: 'WEB_APPS',
};

export const WeekdaysEnum = {
  FRIDAY: 'FRIDAY',
  MONDAY: 'MONDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  THURSDAY: 'THURSDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
};

/** Special Scan Reports */
export const PiiTypeEnum = {
  ADVERTISING_ID: 'ADVERTISING_ID',
  AGE: 'AGE',
  AMERICAN_BANKERS_CUSIP_ID: 'AMERICAN_BANKERS_CUSIP_ID',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AWS_CREDENTIALS: 'AWS_CREDENTIALS',
  AZURE_AUTH_TOKEN: 'AZURE_AUTH_TOKEN',
  BASIC_AUTH_HEADER: 'BASIC_AUTH_HEADER',
  CREDIT_CARD_NUMBER: 'CREDIT_CARD_NUMBER',
  CREDIT_CARD_TRACK_NUMBER: 'CREDIT_CARD_TRACK_NUMBER',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  ENCRYPTION_KEY: 'ENCRYPTION_KEY',
  FEMALE_NAME: 'FEMALE_NAME',
  FIRST_NAME: 'FIRST_NAME',
  GCP_API_KEY: 'GCP_API_KEY',
  GCP_CREDENTIALS: 'GCP_CREDENTIALS',
  GENDER: 'GENDER',
  HTTP_COOKIE: 'HTTP_COOKIE',
  IBAN_CODE: 'IBAN_CODE',
  ICD10_CODE: 'ICD10_CODE',
  ICD9_CODE: 'ICD9_CODE',
  IMEI_HARDWARE_ID: 'IMEI_HARDWARE_ID',
  JAPAN_BANK_ACCOUNT: 'JAPAN_BANK_ACCOUNT',
  JAPAN_DRIVERS_LICENSE_NUMBER: 'JAPAN_DRIVERS_LICENSE_NUMBER',
  JAPAN_INDIVIDUAL_NUMBER: 'JAPAN_INDIVIDUAL_NUMBER',
  JSON_WEB_TOKEN: 'JSON_WEB_TOKEN',
  LAST_NAME: 'LAST_NAME',
  LOCATION: 'LOCATION',
  MAC_ADDRESS: 'MAC_ADDRESS',
  MAC_ADDRESS_LOCAL: 'MAC_ADDRESS_LOCAL',
  MALE_NAME: 'MALE_NAME',
  MEDICAL_TERM: 'MEDICAL_TERM',
  PASSPORT: 'PASSPORT',
  PASSWORD: 'PASSWORD',
  PERSON_NAME: 'PERSON_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  STREET_ADDRESS: 'STREET_ADDRESS',
  SWIFT_CODE: 'SWIFT_CODE',
  US_BANK_ROUTING_MICR: 'US_BANK_ROUTING_MICR',
  US_DRIVERS_LICENSE_NUMBER: 'US_DRIVERS_LICENSE_NUMBER',
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US_EMPLOYER_IDENTIFICATION_NUMBER',
  US_HEALTHCARE_NPI: 'US_HEALTHCARE_NPI',
  US_PASSPORT: 'US_PASSPORT',
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER',
  US_SOCIAL_SECURITY_NUMBER: 'US_SOCIAL_SECURITY_NUMBER',
  WEAK_PASSWORD_HASH: 'WEAK_PASSWORD_HASH',
  XSRF_TOKEN: 'XSRF_TOKEN',
};

export const PiiTypeEnumHumanReadable = {
  ADVERTISING_ID: 'Advertising ID',
  AGE: 'Age',
  AMERICAN_BANKERS_CUSIP_ID: 'American Bankers CUSIP ID',
  AUTH_TOKEN: 'Auth Token',
  AWS_CREDENTIALS: 'AWS Credentials',
  AZURE_AUTH_TOKEN: 'Azure Auth Token',
  BASIC_AUTH_HEADER: 'Basic Auth Header',
  CREDIT_CARD_NUMBER: 'Credit Card Number',
  CREDIT_CARD_TRACK_NUMBER: 'Credit Card Track Number',
  DATE_OF_BIRTH: 'Date of Birth',
  EMAIL_ADDRESS: 'Email Address',
  ENCRYPTION_KEY: 'Encryption Key',
  FEMALE_NAME: 'Female Name',
  FIRST_NAME: 'First Name',
  GCP_API_KEY: 'GCP API Key',
  GCP_CREDENTIALS: 'GCP Credentials',
  GENDER: 'Gender',
  HTTP_COOKIE: 'HTTP Cookie',
  IBAN_CODE: 'IBAN Code',
  ICD10_CODE: 'ICD-10-CM Code',
  ICD9_CODE: 'ICD-9-CM Code',
  IMEI_HARDWARE_ID: 'IMEI Hardware ID',
  JAPAN_BANK_ACCOUNT: 'Japan Bank Account',
  JAPAN_DRIVERS_LICENSE_NUMBER: "Japan Driver's Licence Number",
  JAPAN_INDIVIDUAL_NUMBER: 'Japan Individual Number',
  JSON_WEB_TOKEN: 'JSON Web Token',
  LAST_NAME: 'Last Name',
  LOCATION: 'Location',
  MAC_ADDRESS: 'MAC Address',
  MAC_ADDRESS_LOCAL: 'MAC Address Local',
  MALE_NAME: 'Male Name',
  MEDICAL_TERM: 'Medical Term',
  PASSPORT: 'Passport',
  PASSWORD: 'Password',
  PERSON_NAME: 'Person Name',
  PHONE_NUMBER: 'Phone Number',
  STREET_ADDRESS: 'Street Address',
  SWIFT_CODE: 'SWIFT Code',
  US_BANK_ROUTING_MICR: 'US Bank Routing MICR',
  US_DRIVERS_LICENSE_NUMBER: "US Driver's License Number",
  US_EMPLOYER_IDENTIFICATION_NUMBER: 'US Employer Identification Number',
  US_HEALTHCARE_NPI: 'US Healthcare NPI',
  US_PASSPORT: 'US Passport',
  US_PREPARER_TAXPAYER_IDENTIFICATION_NUMBER: 'US Preparer Taxpayer Identification Number',
  US_SOCIAL_SECURITY_NUMBER: 'US Social Security Number',
  WEAK_PASSWORD_HASH: 'Weak Password Hash',
  XSRF_TOKEN: 'XSRF Token',
};

export const PolicyViolationException = {
  WONT_FIX: 'WONT_FIX', // The user decided to not resolve the policy violation
};

export const policyViolationStatus = {
  ...PolicyViolationException,
  DELETED: 'DELETED',
  OPEN: 'OPEN',
  RESOLVED: 'RESOLVED',
  WONT_FIX: 'WONT_FIX',
};

/** RESTful APIs */
export const RestfulApiStatus = {
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
  ONLINE: 'ONLINE',
};

export const AwsCloudResourcesEnum = {
  AWS_API_GATEWAY_API: 'AWS_API_GATEWAY_API',
  AWS_CLOUD_FRONT: 'AWS_CLOUD_FRONT',
  AWS_CLOUD_TRAIL: 'AWS_CLOUD_TRAIL',
  AWS_DOCDB_INSTANCE: 'AWS_DOCDB_INSTANCE',
  AWS_DYNAMODB_TABLE: 'AWS_DYNAMODB_TABLE',
  AWS_EBS_SNAPSHOT: 'AWS_EBS_SNAPSHOT',
  AWS_EBS_VOLUME: 'AWS_EBS_VOLUME',
  AWS_EC2_AUTOSCALING_GROUP: 'AWS_EC2_AUTOSCALING_GROUP',
  AWS_EC2_VIRTUAL_MACHINE: 'AWS_EC2_VIRTUAL_MACHINE',
  AWS_EFS_FILESYSTEM: 'AWS_EFS_FILESYSTEM',
  AWS_ELASTICACHE_REDIS_CLUSTER: 'AWS_ELASTICACHE_REDIS_CLUSTER',
  AWS_ELASTICSEARCH_DATABASE: 'AWS_ELASTICSEARCH_DATABASE',
  AWS_ELB_LOAD_BALANCER: 'AWS_ELB_LOAD_BALANCER',
  AWS_ELB_V2_LOAD_BALANCER: 'AWS_ELB_V2_LOAD_BALANCER',
  AWS_KINESIS_STREAM: 'AWS_KINESIS_STREAM',
  AWS_KMS_KEY: 'AWS_KMS_KEY',
  AWS_LAMBDA_FUNCTION: 'AWS_LAMBDA_FUNCTION',
  AWS_RDS_CLUSTER: 'AWS_RDS_CLUSTER',
  AWS_RDS_CLUSTER_SNAPSHOT: 'AWS_RDS_CLUSTER_SNAPSHOT',
  AWS_RDS_INSTANCE: 'AWS_RDS_INSTANCE',
  AWS_RDS_INSTANCE_SNAPSHOT: 'AWS_RDS_INSTANCE_SNAPSHOT',
  AWS_RDS_SNAPSHOT: 'AWS_RDS_SNAPSHOT',
  AWS_REDSHIFT_CLUSTER: 'AWS_REDSHIFT_CLUSTER',
  AWS_S3_BUCKET: 'AWS_S3_BUCKET',
  AWS_SAGE_MAKER: 'AWS_SAGE_MAKER',
  AWS_SQS_QUEUE: 'AWS_SQS_QUEUE',
};

export const AzureCloudResourcesEnum = {
  AZURE_API_MANAGEMENT_SERVICE: 'AZURE_API_MANAGEMENT_SERVICE',
  AZURE_API_MANAGEMENT_SERVICE_API: 'AZURE_API_MANAGEMENT_SERVICE_API',
  AZURE_APPLICATION_GATEWAY: 'AZURE_APPLICATION_GATEWAY',
  AZURE_COSMOSDB_INSTANCE: 'AZURE_COSMOSDB_INSTANCE',
  AZURE_COSMOSDB_SERVER: 'AZURE_COSMOSDB_SERVER',
  AZURE_FUNCTION: 'AZURE_FUNCTION',
  AZURE_FUNCTION_APP: 'AZURE_FUNCTION_APP',
  AZURE_KEY_VAULT: 'AZURE_KEY_VAULT',
  AZURE_KEY_VAULT_SECRET: 'AZURE_KEY_VAULT_SECRET',
  AZURE_MARIADB_INSTANCE: 'AZURE_MARIADB_INSTANCE',
  AZURE_MARIADB_SERVER: 'AZURE_MARIADB_SERVER',
  AZURE_POSTGRESQL_DATABASE: 'AZURE_POSTGRESQL_DATABASE',
  AZURE_POSTGRESQL_SERVER: 'AZURE_POSTGRESQL_SERVER',
  AZURE_SQLDB_INSTANCE: 'AZURE_SQLDB_INSTANCE',
  AZURE_SQLDB_SERVER: 'AZURE_SQLDB_SERVER',
  AZURE_STORAGE_ACCOUNT: 'AZURE_STORAGE_ACCOUNT',
  AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER: 'AZURE_STORAGE_ACCOUNT_BLOB_CONTAINER',
  AZURE_WEB_APP: 'AZURE_WEB_APP',
};

export const GcpCloudResourcesEnum = {
  GCP_APP_ENGINE_SERVICE: 'GCP_APP_ENGINE_SERVICE',
  GCP_BIGTABLE_DATABASE: 'GCP_BIGTABLE_DATABASE',
  GCP_CLOUD_FUNCTION: 'GCP_CLOUD_FUNCTION',
  GCP_CLOUD_SQL_INSTANCE: 'GCP_CLOUD_SQL_INSTANCE',
  GCP_CLOUD_STORAGE_BUCKET: 'GCP_CLOUD_STORAGE_BUCKET',
  GCP_FIREBASE_FIRESTORE_DATABASE: 'GCP_FIREBASE_FIRESTORE_DATABASE',
  GCP_FIREBASE_FUNCTION: 'GCP_FIREBASE_FUNCTION',
  GCP_FIREBASE_REALTIME_DATABASE: 'GCP_FIREBASE_REALTIME_DATABASE',
  GCP_FIREBASE_STORAGE_BUCKET: 'GCP_FIREBASE_STORAGE_BUCKET',
  GCP_KUBERNETES_ENGINE_CLUSTER: 'GCP_KUBERNETES_ENGINE_CLUSTER',
  GCP_VIRTUAL_MACHINE: 'GCP_VIRTUAL_MACHINE',
};

export const MulesoftCloudResourcesEnum = {
  MULESOFT_HTTP_API: 'MULESOFT_HTTP_API',
  MULESOFT_REST_API: 'MULESOFT_REST_API',
};

export const UnknownCloudResourceEnum = { UNKNOWN: 'UNKNOWN' };

export const CloudResourceTypeDefinition = {
  ...UnknownCloudResourceEnum,
  ...AwsCloudResourcesEnum,
  ...AzureCloudResourcesEnum,
  ...GcpCloudResourcesEnum,
  ...MulesoftCloudResourcesEnum,
};

/** Events */
export const NetworkEventTypesDefinition = {
  NETWORK_SERVICE_DISCOVERED: 'NETWORK_SERVICE_DISCOVERED',
  NETWORK_SERVICE_WENT_OFFLINE: 'NETWORK_SERVICE_WENT_OFFLINE',
  NETWORK_SERVICE_WENT_ONLINE: 'NETWORK_SERVICE_WENT_ONLINE',
};

export const WebAppEventTypesDefinition = {
  WEB_APPLICATION_DISCOVERED: 'WEB_APPLICATION_DISCOVERED',
  // TODO(AD): Use for POR-1593
  WEB_APPLICATION_NO_LONGER_ACCESSIBLE: 'WEB_APPLICATION_NO_LONGER_ACCESSIBLE',
};

export const EventTypesDefinition = {
  APIGEE_AUTHENTICATOR_CREATED: 'APIGEE_AUTHENTICATOR_CREATED',

  API_OPERATION_CREATED: 'API_OPERATION_CREATED',

  API_OPERATION_NO_LONGER_ACCESSIBLE: 'API_OPERATION_NO_LONGER_ACCESSIBLE',

  API_OPERATION_UPDATED: 'API_OPERATION_UPDATED',

  AWS_AUTHENTICATOR_CREATED: 'AWS_AUTHENTICATOR_CREATED',

  AXWAY_AUTHENTICATOR_CREATED: 'AXWAY_AUTHENTICATOR_CREATED',

  AXWAY_AUTHENTICATOR_UPDATED: 'AXWAY_AUTHENTICATOR_UPDATED',

  AZURE_AUTHENTICATOR_CREATED: 'AZURE_AUTHENTICATOR_CREATED',

  AZURE_AUTHENTICATOR_UPDATED: 'AZURE_AUTHENTICATOR_UPDATED',

  CERTIFICATE_CHAIN_DISCOVERED: 'CERTIFICATE_CHAIN_DISCOVERED',

  CERTIFICATE_CHAIN_REMOVED: 'CERTIFICATE_CHAIN_REMOVED',

  CERTIFICATE_DISCOVERED: 'CERTIFICATE_DISCOVERED',

  CLOUD_RESOURCE_DISCOVERED: 'CLOUD_RESOURCE_DISCOVERED',

  // TODO(AD): Use for POR-1593
  CLOUD_RESOURCE_NO_LONGER_ACCESSIBLE: 'CLOUD_RESOURCE_NO_LONGER_ACCESSIBLE',

  COMMENT_CREATED: 'COMMENT_CREATED',

  COMMENT_DELETED: 'COMMENT_DELETED',

  GCP_AUTHENTICATOR_CREATED: 'GCP_AUTHENTICATOR_CREATED',

  GCP_AUTHENTICATOR_KEY_UPDATED: 'GCP_AUTHENTICATOR_KEY_UPDATED',

  MULESOFT_AUTHENTICATOR_CREATED: 'MULESOFT_AUTHENTICATOR_CREATED',

  OPENAPI_DEFINITION_CREATED: 'OPENAPI_DEFINITION_CREATED',

  POLICY_RULE_CREATED: 'POLICY_RULE_CREATED',

  POLICY_RULE_DELETED: 'POLICY_RULE_DELETED',

  POLICY_VIOLATION_CREATED: 'POLICY_VIOLATION_CREATED',

  POLICY_VIOLATION_EXCEPTION_ADDED: 'POLICY_VIOLATION_EXCEPTION_ADDED',

  POLICY_VIOLATION_RESOLVED: 'POLICY_VIOLATION_RESOLVED',

  RESTFUL_API_DISCOVERED: 'RESTFUL_API_DISCOVERED',
  // TODO(AD): Use for POR-1593
  RESTFUL_API_NO_LONGER_ACCESSIBLE: 'RESTFUL_API_NO_LONGER_ACCESSIBLE',
  RESTFUL_API_UPDATED: 'RESTFUL_API_UPDATED',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  ...NetworkEventTypesDefinition,
  ...WebAppEventTypesDefinition,
};

export const EventImportancesDefinition = {
  CRITICAL: 'CRITICAL',
  INFORMATIONAL: 'INFORMATIONAL',
  RESOLUTION: 'RESOLUTION',
  WARNING: 'WARNING',
};

/** Certificate Chains */
export const SecurityPropertiesEnum = {
  EMBEDS_CERTIFICATE_TRANSPARENCY_SCT: 'EMBEDS_CERTIFICATE_TRANSPARENCY_SCT',
  IS_EXPIRING: 'IS_EXPIRING',
  LEGACY_SYMANTEC_ANCHOR: 'LEGACY_SYMANTEC_ANCHOR',
  MATCHES_HOSTNAME: 'MATCHES_HOSTNAME',
  RSA_KEY_SMALLER_THAN_2048_BITS: 'RSA_KEY_SMALLER_THAN_2048_BITS',
  SUPPORTS_MUST_STAPLE: 'SUPPORTS_MUST_STAPLE',
  VULNERABLE_TO_SHA1_COLLISION: 'VULNERABLE_TO_SHA1_COLLISION',
};

/** Web applications */
export const WebRequestTypeEnum = {
  DOCUMENT: 'DOCUMENT',
  EVENTSOURCE: 'EVENTSOURCE',
  FETCH: 'FETCH',
  FONT: 'FONT',
  IMAGE: 'IMAGE',
  MANIFEST: 'MANIFEST',
  MEDIA: 'MEDIA',
  OTHER: 'OTHER',
  SCRIPT: 'SCRIPT',
  STYLESHEET: 'STYLESHEET',
  TEXTTRACK: 'TEXTTRACK',
  WEBSOCKET: 'WEBSOCKET',
  XHR: 'XHR',
};

export const WebApplicationTypeEnum = {
  HYBRID: 'HYBRID',
  SINGLE_PAGE: 'SINGLE_PAGE',
  TRADITIONAL: 'TRADITIONAL',
};

export const WebApplicationStatusEnum = {
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
  ONLINE: 'ONLINE',
};

export const GraphQlApiStatus = {
  NO_LONGER_ACCESSIBLE: 'NO_LONGER_ACCESSIBLE',
  ONLINE: 'ONLINE',
};
