import { HostedOnEnum } from '@dt/horizon-api';
import { first, orderBy, pipe } from 'lodash/fp';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';

// Returns NetworkServiceDecorated or null if there is no matching domain name
export function decorate(network_service, domain_names, policy_violations_decorated, tls_scan_results) {
  const domain = domain_names.find(({ id }) => network_service.domain_name_id === id);

  if (!domain) {
    return null;
  }

  const matching_policy_violations_decorated = policy_violations_decorated
    .filter(({ id }) => network_service.policy_violation_ids && network_service.policy_violation_ids.includes(id))
    .filter(Boolean);

  const tls_scan_result = tls_scan_results.find(({ network_service_id }) => network_service_id === network_service.id);

  const tls_scan_results_decorated = tls_scan_result
    ? [
        {
          network_service_id: tls_scan_result.network_service_id,
          tls_scan_attributes: tls_scan_result.tls_scan_attributes.map(tls_scan_attribute => ({
            ...tls_scan_attribute,
            opened_policy_violation: policy_violations_decorated.find(
              ({ id }) => id === tls_scan_attribute.opened_policy_violation_id,
            ),
            resolved_policy_violation: policy_violations_decorated.find(
              ({ id }) => id === tls_scan_attribute.resolved_policy_violation_id,
            ),
          })),
        },
      ]
    : null;

  return {
    ...network_service,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: matching_policy_violations_decorated,
    }),
    asset_type: AssetTypeDict.API_DOMAIN,
    domain_name: domain,
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: matching_policy_violations_decorated,
    }),
    name: domain.name,
    policy_violations_decorated: matching_policy_violations_decorated,
    tls_scan_results_decorated,
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue({
      policy_violations_decorated: matching_policy_violations_decorated,
    }),
  };
}

export const network_services = ({ network_services }) => network_services;

export const getLatestConnectivityResult = pipe(orderBy('date_created')('desc'), first);

const HostedOnDisplayNameDict = {
  [HostedOnEnum.AMAZON_WEB_SERVICES]: 'AWS',
  [HostedOnEnum.GOOGLE_CLOUD_PLATFORM]: 'GCP',
  [HostedOnEnum.APIGEE]: 'Apigee',
  [HostedOnEnum.AZURE]: 'Azure',
  [HostedOnEnum.MULESOFT]: 'Mulesoft',
  [HostedOnEnum.KONG]: 'Kong',
  [HostedOnEnum.DETECTION_FAILED]: 'Undetected',
  [HostedOnEnum.AXWAY]: 'Axway',
  [HostedOnEnum.ON_PREMISE]: 'On Premise',
  [HostedOnEnum.GITHUB]: 'Github',
  [HostedOnEnum.GITHUB_ENTERPRISE_SERVER]: 'Github Enterprise Server',
  [HostedOnEnum.BITBUCKET]: 'Bitbucket',
  [HostedOnEnum.CLOUDFLARE]: 'Cloudflare',
  [HostedOnEnum.AKAMAI]: 'Akamai',
  [HostedOnEnum.UNKNOWN]: 'Unknown',
};

export const getDisplayNameForHostedOn = hosted_on => {
  if (HostedOnDisplayNameDict[hosted_on]) {
    return HostedOnDisplayNameDict[hosted_on];
  } else {
    throw new Error(`Missing hosted on string for ${hosted_on}, HostedOn is likely out of date`);
  }
};
